<template lang="pug">
.your-theme-latest(v-if="getPossibleTemplates().length")
  .your-theme-row
    CollectionPreviews.your-theme-row__block(
      :name="lastUserTheme.name"
      :to="getUserThemeRoute(lastUserTheme.slug)"
      :useCaseMap="useCaseMap"
      :templates="lastUserTheme.templates"
      :themeKit="getThemeKit(lastUserTheme)"
      :baseThemeKitName="baseThemeName"
    )
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import CollectionPreviews from '../CollectionPreviews.vue';
  import filterHelperMixin from '../../mixins/filterHelper';

  export default {
    name: 'YourThemes',
    components: {
      CollectionPreviews,
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
    },
    mixins: [filterHelperMixin],
    computed: {
      ...mapState(['accountType']),
      ...mapState('templateChooser', ['search']),
      ...mapGetters('templateChooser', ['lastUserTheme']),
      ...mapState('useCase', ['useCaseMap']),
      baseThemeName() {
        return this.lastUserTheme?.base?.name;
      },
    },

    methods: {
      getPossibleTemplates() {
        return this.lastUserTheme?.templates || [];
      },
      getUserThemeRoute(slug) {
        return {
          name: 'your-themes-templates',
          params: { slug },
        };
      },
      getThemeKit(theme) {
        const themeKit = { ...theme.themeKit };
        themeKit.id = themeKit?.id || theme._id;
        return themeKit;
      },
    },
  };
</script>

<style lang="sass">
  .goals-row__block
    margin-bottom: 3.75rem
</style>
